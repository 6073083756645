<template>
  <div>
    <LoadingState v-if="isLoading" />
    <template v-else>
      <h1>Add Teacher</h1>
      <a-row>
        <a-col :span="24">
          <div class="card-full">
            <div class="detail">
              <a-form @submit.prevent="add">
                <a-col :span="24">
                  <a-form-item label="NIK">
                    <a-input v-model="newData.nik" style="height: 48px" />
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item label="Full Name">
                    <a-input v-model="newData.nama" style="height: 48px" />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="Gender">
                    <a-radio-group v-model="newData.jenis_kelamin">
                      <a-radio value="Laki-Laki">Male</a-radio>
                      <a-radio value="Perempuan">Female</a-radio>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item label="Subject">
                    <a-select size="large" v-model="newData.id_mata_pelajaran" read-only style="height: 48px">
                      <a-select-option v-for="mapel in daftarMapels" :key="mapel.id" :value="mapel.id">{{mapel.nama}}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item label="Default Password">
                    <a-input v-model="newData.nik" read-only style="height: 48px" />
                  </a-form-item>
                </a-col>
                <a-button
                  :loading="loadingAdd"
                  type="primary"
                  html-type="submit"
                  size="large"
                  block
                >Add Now</a-button>
              </a-form>
            </div>
          </div>
        </a-col>
      </a-row>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newData: {
        nik: '',
        nama: '',
        jenis_kelamin: '',
        id_mata_pelajaran: '',
      },
      daftarMapels: [],
      loadingAdd: false,
      isLoading: false,
    }
  },
  components: {
    LoadingState: () => import('@/components/app/LoadingState'),
  },
  methods: {
    add() {
      if (this.newData.nik && this.newData.nama) {
        this.loadingAdd = true
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure to add this account ?</div>
          ),
          onOk: () => {
            this.loadingAdd = false
            const data = {
              nik: this.newData.nik,
              nama: this.newData.nama,
              jenis_kelamin: this.newData.jenis_kelamin,
            }
            const idMapel = this.newData.id_mata_pelajaran
            this.$store.dispatch('admin/ADD_TEACHER', { data, idMapel })
              .then(res => {
                if (res === 201) {
                  this.$notification.success({
                    message: 'Success',
                    description:
                      'this account has been added',
                  })
                  this.$router.push({ name: 'Teacher Admin' })
                } else if (res === 'nik must be unique') {
                  this.$notification.error({
                    message: 'Error',
                    description:
                      'this NIK has already taken',
                  })
                } else {
                  this.$notification.error({
                    message: 'Error',
                    description:
                      'this account has not been added',
                  })
                }
              })
          },
          onCancel: () => {
            this.loadingAdd = false
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Add',
        })
      } else {
        this.$notification.error({
          message: 'Error',
          description:
            'You must fill NIK and Nama',
        })
      }
    },
  },
  created() {
    this.isLoading = true
    this.$store.dispatch('curriculum/FETCH_CURRICULUM_ALL_MAPEL_KBM')
      .then(data => {
        // console.log(data)
        this.isLoading = false
        this.daftarMapels = data.data
        this.newData.id_mata_pelajaran = this.daftarMapels[0].id
      })
  },
}
</script>

<style scoped>
.card-full {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail {
  padding: 40px 20px 20px 20px;
}
</style>
